<template>
  <Member
    title="Dr. Alessandro Girotto"
    description="Nurse Navigator, infermiere"
    text="Il Navigator Nurse è il professionista punto di riferimento del paziente e della sua famiglia che collabora nella pianificazione e nell’attuazione di un percorso di cura personalizzato, che tenga in considerazione le più recenti evidenze scientifiche.

Da febbraio 2019 Navigator Nurse presso IRCCS Ospedale San Raffaele, Dipartimento di chirurgia generale e specialistica, Unità operativa di chirurgia gastroenterologica

Da ottobre 2016 professore a contratto presso l’Università Vita-Salute San Raffaele – Milano, Facoltà di Medicina e Chirurgia.

Modulo di “Infermieristica clinica in chirurgia” all’interno dell’insegnamento “Infermieristica clinica in area chirurgica” (SSD MED/45)

Da gennaio 2015 a gennaio 2019 infermiere di degenza presso IRCCS Ospedale San Raffaele, Dipartimento di chirurgia generale e specialistica, Unità operativa di chirurgia gastroenterologica, pancreatica, epatobiliare, bariatrica e medicina dei trapianti"
    :formation="['Corso di Laurea in Infermieristica', '', '', '']"
    publication="Modulo di “Infermieristica clinica in chirurgia” all’interno dell’insegnamento “Infermieristica clinica in area chirurgica” (SSD MED/45)

Da gennaio 2015 a gennaio 2019 infermiere di degenza presso IRCCS Ospedale San Raffaele, Dipartimento di chirurgia generale e specialistica, Unità operativa di chirurgia gastroenterologica, pancreatica, epatobiliare, bariatrica e medicina dei trapianti"
    :headerImage="localImage"
    :pub="false"
  />
</template>

<script>
import Member from "../routes/Member";
import teamImage from "@/assets/member-background.jpg";
export default {
  name: "Girotto",
  components: {
    Member,
  },
  data() {
    return {
      localImage: teamImage,
    };
  },
};
</script>
