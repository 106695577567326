<template>
  <div>
    <AppMenu />
    <div class="relative mt-8 bg-cover" :style="styleImage">
      <div
        class="relative px-4 py-12 mx-auto max-w-7xl sm:px-6 lg:px-8 lg:py-16"
      >
        <div class="px-10 py-16 bg-white md:mr-auto md:w-1/2">
          <p
            class="mt-2 text-3xl font-extrabold tracking-tight text-black sm:text-4xl"
          >
            {{ title }}
          </p>
          <p class="mt-3 text-lg text-gray-500 text-light">
            {{ description }}
          </p>
        </div>
      </div>
    </div>
    <div class="px-4 py-16 overflow-hidden bg-white xl:py-18 sm:px-6 lg:px-8">
      <div class="mx-auto max-w-max lg:max-w-7xl">
        <div class="relative">
          <svg
            class="absolute top-0 right-0 hidden -mt-20 -mr-20 md:block"
            width="404"
            height="384"
            fill="none"
            viewBox="0 0 404 384"
            aria-hidden="true"
          >
            <defs>
              <pattern
                id="95e8f2de-6d30-4b7e-8159-f791729db21b"
                x="0"
                y="0"
                width="20"
                height="20"
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x="0"
                  y="0"
                  width="4"
                  height="4"
                  class="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width="404"
              height="384"
              fill="url(#95e8f2de-6d30-4b7e-8159-f791729db21b)"
            />
          </svg>
          <svg
            class="absolute bottom-0 left-0 hidden -mb-20 -ml-20 md:block"
            width="404"
            height="384"
            fill="none"
            viewBox="0 0 404 384"
            aria-hidden="true"
          >
            <defs>
              <pattern
                id="7a00fe67-0343-4a3c-8e81-c145097a3ce0"
                x="0"
                y="0"
                width="20"
                height="20"
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x="0"
                  y="0"
                  width="4"
                  height="4"
                  class="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width="404"
              height="384"
              fill="url(#7a00fe67-0343-4a3c-8e81-c145097a3ce0)"
            />
          </svg>
          <div class="relative md:bg-white md:p-6">
            <div class="mb-24">
              <div
                class="prose prose-lg text-gray-800 prose-indigo lg:max-w-none"
              >
                <p>{{ text }}</p>
              </div>
            </div>
            <hr />
            <div class="my-24">
              <h3
                class="mt-2 mb-8 text-3xl font-extrabold tracking-tight text-black sm:text-4xl"
              >
                Formazione
              </h3>
              <div
                class="prose prose-lg text-gray-800 prose-indigo lg:max-w-none"
              >
                <p v-for="item in formation" :key="item">
                  {{ item }}
                </p>
              </div>
            </div>
            <hr />
            <div class="mt-24" v-show="pub">
              <h3
                class="mt-2 mb-8 text-3xl font-extrabold tracking-tight text-black sm:text-4xl"
              >
                Pubblicazioni
              </h3>
              <div
                class="prose prose-lg text-gray-800 prose-indigo lg:max-w-none"
              >
                <p>{{ publication }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <FooterSection />
  </div>
</template>

<script>
import AppMenu from "../components/AppMenu";
import FooterSection from "../components/FooterSection.vue";

export default {
  name: "Member",
  components: {
    AppMenu,
    FooterSection,
  },
  props: {
    headerImage: String,
    title: String,
    description: String,
    text: String,
    formation: Array,
    publication: String,
    pub: Boolean,
  },
  data() {
    return {
      styleImage: {
        backgroundImage: `url('${this.headerImage}')`,
      },
    };
  },
};
</script>
